// TODO: Localize this file

import type { Comment, CommentFormType, DisplayUser, FlipResponse, GroupUser, Member, User } from 'types';

export function formatName(
  item: User | FlipResponse | Member | Comment | CommentFormType | GroupUser | DisplayUser,
  showFullName = false,
) {
  const fullName = item.full_name;
  const firstName = item.first_name;
  const lastName = item.last_name;
  const displayName = item.display_name;
  let name;
  if (displayName) {
    name = displayName;
  } else if (lastName && showFullName) {
    name = `${firstName} ${lastName}`;
  } else if (fullName) {
    name = fullName;
  } else if (lastName) {
    name = `${firstName} ${lastName.charAt(0)}`;
  } else {
    name = firstName;
  }
  return name;
}

export function getFirstLastNameOrFallback(
  item: User | FlipResponse | Member | Comment | CommentFormType | GroupUser | DisplayUser,
) {
  const fullName = item?.full_name;
  const firstName = item?.first_name;
  const lastName = item?.last_name;
  const displayName = item?.display_name;
  let name;
  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (fullName) {
    name = fullName;
  } else if (displayName) {
    name = displayName;
  } else if (lastName) {
    name = `${firstName} ${lastName.charAt(0)}`;
  } else {
    name = firstName;
  }
  return name;
}

export function formatSeconds(seconds: number) {
  const s = seconds === 0 ? 1 : seconds;
  const minutes = Math.floor(s / 60);
  const secondsLeft = Math.ceil(s % 60);
  return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
}
